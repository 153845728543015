import NotificationDispatcher from "../js/notification_dispatcher";

class CacheChecker extends HTMLElement {
  static tagName = "cache-checker";
  static observedAttributes = ["force"];

  constructor() {
    super();
    this.book = this.getAttribute("book");
    this.addEventListener("click", this);
  }

  attributeChangedCallback(name, oldValue, newValue) {
    console.log(
      `Attribute ${name} has changed on ${this.closest("li").dataset.title}: ${newValue}`,
    );
    this.connectionSetup();
  }

  connectedCallback() {
    console.log(`connected: ${this.closest("li").dataset.title}`);
    this.connectionSetup();
  }

  async connectionSetup() {
    this.cache = await caches.open("v1");
    this.url = "/books/" + this.book;
    this.checkCacheStatus();
    const mutationObserver = (mutationList, observer) => {
      for (const m of mutationList) {
        if (m.target === this) {
          console.log("mutated");
          this.checkCacheStatus();
        }
      }
    };
    this.myObserver = new MutationObserver(mutationObserver);
    this.myObserver.observe(this, { childList: true, subtree: true });
  }

  async handleEvent(event) {
    switch (event.type) {
      case "click":
        if (event.target.closest("[data-prefetch]")) {
          this.cacheBook();
        } else {
          this.removeBook();
        }
        break;
      default:
        break;
    }
  }

  async checkCacheStatus() {
    const response = await this.cache.match(this.url);
    if (response == undefined) {
      this.querySelector("[data-prefetch]").hidden = false;
      this.querySelector("[data-remove]").hidden = true;
    } else {
      this.querySelector("[data-prefetch]").hidden = true;
      this.querySelector("[data-remove]").hidden = false;
    }
  }

  addLoadingIndicator(btn) {
    btn.disabled = true;
    btn.setAttribute("aria-busy", true);
    btn.querySelector("svg").style.visibility = "hidden";
    btn.querySelector("span").innerHTML = "Loading...";
  }

  async removeLoadingIndicator(btn) {
    btn.removeAttribute("disabled");
    btn.removeAttribute("aria-busy");
    btn.querySelector("svg").hidden = false;
    btn.querySelector("svg").style.visibility = "visible";
    btn.querySelector("span").innerHTML = "Make available offline";
  }

  async cacheBook() {
    const btn = this.querySelector("[data-prefetch]");
    this.addLoadingIndicator(btn);
    await this.cache.add(this.url);

    const notification = new NotificationDispatcher(
      "Finished",
      `${this.closest("li").dataset.title} now available offline`,
    );
    notification.dispatch();
    await this.removeLoadingIndicator(btn);
    this.checkCacheStatus();
  }

  async removeBook() {
    await this.cache.delete(this.url);
    this.checkCacheStatus();
  }
}

customElements.define(CacheChecker.tagName, CacheChecker);
