class CarouselElement extends HTMLElement {
  static tagName = "carousel-element";

  #myObserver;
  constructor() {
    super();
    this.addEventListener("dblclick", this);
    this.addEventListener("click", this);
    window.addEventListener("keydown", this);
    this.addEventListener("scrollend", this);
  }

  connectedCallback() {
    const mutationObserver = (mutationList, observer) => {
      for (const m of mutationList) {
        if (m.target === this) {
          console.log("mutated!");
          this.setCurrentPage();
        }
      }
    };
    this.#myObserver = new MutationObserver(mutationObserver);
    this.#myObserver.observe(this, {
      childList: true,
      subtree: true,
    });
  }

  disconnectedCallback() {
    this.#myObserver.disconnect();
  }

  handleEvent(event) {
    switch (event.type) {
      case "dblclick":
        this.toggleFullscreen();
        break;
      case "click":
        if (event.target.nodeName === "IMG") {
          console.log("toggle menu");
        } else if (event.target.nodeName === "BUTTON") {
          const nextSlide = event.target.dataset.navigation;
          this.slideTo(nextSlide);
        }
        break;
      case "scrollend":
        this.setCurrentPage();
        break;
      case "keydown":
        if (event.code == "ArrowLeft") {
          event.preventDefault();
          this.slideTo(this.currentPage - 1);
        } else if (event.code == "ArrowRight") {
          event.preventDefault();
          this.slideTo(this.currentPage + 1);
        }
        break;
      default:
        break;
    }
  }

  setCurrentPage() {
    const calculatedIndex = Math.round(
      (this.scrollLeft / this.scrollWidth) * this.pageCount,
    );
    this.setAttribute("current-page", calculatedIndex + 1);
    this.paginationDispatch(calculatedIndex + 1, this.pageCount);
  }

  slideTo(slideIndex) {
    const nextPage = this.querySelector(`[page='${slideIndex}']`);
    if (nextPage) {
      nextPage.scrollIntoView({ behavior: "smooth" });
    }
  }

  toggleFullscreen() {
    if (!document.fullscreenElement) {
      this.closest("section").requestFullscreen();
    } else {
      document.exitFullscreen();
    }
  }

  paginationDispatch(currentPage, pageCount) {
    const paginationEvent = new CustomEvent("book:page-change", {
      detail: {
        currentPage: currentPage,
        pageCount: pageCount,
      },
    });
    window.dispatchEvent(paginationEvent);
  }

  get pageCount() {
    return parseInt(this.getAttribute("page-count"), 10);
  }

  get currentPage() {
    return parseInt(this.getAttribute("current-page"), 10);
  }
}

customElements.define(CarouselElement.tagName, CarouselElement);
