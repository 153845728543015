export default class NotificationDispatcher {
  constructor(title, body) {
    this.title = title;
    this.options = {
      body,
    };
  }

  async dispatch() {
    await Notification.requestPermission();
    const swRegistration = await navigator.serviceWorker.getRegistration();
    swRegistration.showNotification(this.title, this.options);
  }
}
