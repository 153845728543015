import "../channels/cable_ready_init";
import "../js/filter_search";
import "../js/book_info_disclosure";
import "../js/cache_checker";
import "../js/carousel_element";
import "../js/pagination_indicator";
import "../css/style.css";
import mrujs from "mrujs";
import { CableCar } from "mrujs/plugins";
mrujs.start({
  plugins: [new CableCar(CableReady)],
});
console.log("Vite ⚡️ Rails");
// // Import all channels.
// const channels = import.meta.globEager('./**/*_channel.js')
if ("serviceWorker" in navigator) {
  window.addEventListener("load", () => {
    navigator.serviceWorker.register("/service-worker.js").then(
      (registration) => {
        console.log("Service worker registration succeeded:", registration);
      },
      (error) => {
        console.error(`Service worker registration failed: ${error}`);
      },
    );
  });
} else {
  console.error("Service workers not supported");
}

document.addEventListener("cable-ready:after-update", () => {
  document.querySelectorAll("cache-checker").forEach((el) => {
    el.setAttribute("force", true);
  });
});
