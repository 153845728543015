class BookInfoDisclosure extends HTMLElement {
  static tagName = "book-info-disclosure";
  constructor() {
    super();
    this.dialog = this.closest("li").querySelector("dialog");
    this.addEventListener("click", this);
  }
  handleEvent(event) {
    if (event.type === "click") {
      this.disclose();
    }
  }
  disclose() {
    this.dialog.showModal();
  }
}
customElements.define(BookInfoDisclosure.tagName, BookInfoDisclosure);
