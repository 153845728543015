import debounced from "debounced";

class FilterSearch extends HTMLElement {
  static tagName = "filter-search";

  constructor() {
    super();
    debounced.initialize(["input"], { wait: 50 });
    this.form = this.querySelector("form");
    this.form.addEventListener("debounced:input", this);
  }

  handleEvent(event) {
    if (event.type === "debounced:input") this.submitSearch();
  }

  submitSearch() {
    const query = this.form.querySelector("input").value;
    if (!(query.length === 2 || query.length === 1)) {
      this.form.requestSubmit();
    }
  }
}

customElements.define(FilterSearch.tagName, FilterSearch);
