class PaginationIndicator extends HTMLElement {
  connectedCallback() {
    window.addEventListener("book:page-change", this);
  }

  disconnectedCallback() {
    window.removeEventListener("book:page-change", this);
  }

  handleEvent(event) {
    switch (event.type) {
      case "book:page-change":
        this.setPaginationIndicator(
          event.detail.currentPage,
          event.detail.pageCount,
        );
        break;
      default:
    }
  }

  async setPaginationIndicator(currentPage, pageCount) {
    this.hidden = false;
    this.innerHTML = `<output>Page ${currentPage} of ${pageCount}</output>`;
    await new Promise((resolve) => setTimeout(resolve, 2000));
    this.hidden = true;
  }
}

customElements.define("pagination-indicator", PaginationIndicator);
